@font-face {
  font-family: "BerlinSansFBDemi-Bold";
  src: url('../fonts/BerlinSansFBDemi-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ArialMT";
  src: url('../fonts/ArialMT2.ttf');
  font-weight: bold;
  font-style: normal;
}

@function vant2d($px) {
  @return calc($px / 375 * 750)
}



#rotate90 {
  width: 100vh;
  height: 100vw;
  transform-origin: 0px 0px;
  transform: rotate(-90deg) translate(-100vh, 0px);
  .award-box{
    width: 100vh!important;
  }
}

#rotate_z90 {
  width: 100vh;
  height: 100vw;
  transform-origin: 0px 0px;
  transform: rotate(90deg) translate(0px, -100vw);
}

* {
  font-family: ArialMT;
}

// 媒体查询 横屏时 html高度为 100vh
@media screen and (orientation: landscape) {
  html {
    height: 100vh;
  }
  .award-box{
    width: 100vh!important;
  }
}

body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  height: 100vh;
  box-sizing: border-box;
  #app {
    height: 100%;
    background-color: #e7e6ed;
  }
}

.title{
  font-family: ArialMT;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #010000;
  margin-bottom: 60px;
}

.other {
  border-bottom: 1px solid #c0bfc6;
  width: 330px;
  padding: 0;
  margin-left: 10px;
}
.van-toast{
  width: vant2d(166px);
}

.van-toast__icon{
  font-size: vant2d(36px);
}
.van-toast__text{
  font-size: vant2d(14px);
  line-height: vant2d(20px);
  word-break: break-word;
  word-break: auto-phrase;
}

.van-cell{
  font-size: vant2d(14px);
  line-height: vant2d(20px);
}

.van-picker{
  .van-picker__cancel,.van-picker__confirm{
    font-size: vant2d(14px);
  }
  .van-picker-column{
    font-size: vant2d(16px);
  }
}

.necessary {
  position: relative;
  &::after {
    content: '*';
    position: absolute;
    top: -5px;
    left: 100%;
    margin-left: 5px;
    color: red;
  }
}
